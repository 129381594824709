<!--
 * @Description: 参与指南
 * @Author: wangying
 * @Date: 2021-10-09 09:43:06
-->
<template>
  <div>
    <div>
      <div class="title">
        <ListTitle :headerNmae="headerNmae" :headerNmaeCh="headerNmaeCh"></ListTitle>
      </div>
      <p class="hr"></p>
      <div class="contentView">
        <div v-html="content"></div>
        <!-- <iframe frameborder="0" seamless class="iframeView" src="/房地产绿色供应链行动参与指南.pdf">
          <p>您的浏览器不支持框架。</p>
        </iframe> -->
      </div>
    </div>
  </div>
</template>

<script>
import ListTitle from "@/components/reuse/listTitle";
import { articleListByPage, articleDetail } from '@/api'
// import '@/css/content.less'

export default {
  data() {
    return {
      headerNmaeCh: '<p style="color: #57802b;">Participation Guide</p>',
      headerNmae:
        '<p style="color: #57802b; letter-spacing:2px; font-size: 25px;">《房地产绿色供应链行动参与指南》</p>',
      content: ''
    }
  },
  mounted() {
    this.hub.$emit('tatleNames')
    this.hub.$emit('theCover', this.$route.meta.flagNam)

    this.hub.$emit('scrollToz')
    const params = {
      articleId: 'WZNR201907291353270075',
      isEn: this.$store.state.language === 'en' ? 1 : 0
    }
    articleDetail(params).then((res) => {
      console.log(res);
      this.loading = false
      console.log(res.content);
      // item.articleFileList = res.data.articleFileList
      // this.list = [res.data]
      this.content = res.data.content
    })
  },
  components: {
    ListTitle
  }
}
</script>
<style lang="less" scoped>
.fx {
  display: flex;
  justify-content: center;
  margin: 50px 0 20px 0;
}

.title {
  margin-top: 30px;
}

.hr {
  margin-top: 34px;
  margin-right: 22px;
  margin-bottom: 10px;
  border-top: 2px solid #57802b;
}

.iframeView {
  width: 100%;
  height: 1000px;
}

.contentView {
  width: 1020px;
}

/deep/ .contentView img {
  max-width: 1020px;
  height: auto !important;
}
</style>